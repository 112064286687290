<template>
  <div>
    <title>SIM INVENTORY ~ REQUEST MATERIAL</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        List Request Material Data
        <br />
        <h4>The following is a list of transaction data request material</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-12">
          <router-link to="/addrequest-material">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"></i> Add Request Material</button
            ><br /><br />
          </router-link>
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">List Request Material</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Order Number"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Request Number</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                      <th>Item Name</th>
                      <th>Request Date</th>
                      <th>Item Code</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_request }}</td>
                      <td>{{ classdata.nomor_order }}</td>
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.tanggal_request }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <router-link
                          :to="'/edit-request-material/' + classdata.id"
                        >
                          <button
                            title="Edit Request Material"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        <br />
                        <p></p>
                        <button
                          title="Delete order list"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>

            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Request Number</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                      <th>Item Name</th>
                      <th>Request Date</th>
                      <th>Item Code</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="10" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      status: [],
      labeldata: []
    };
  },
  created() {
    // this.fetchDataBuyerOrder();
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      // this.fetchData();

      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      // this.fetchData();
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },

    async fetchDataBuyerOrder() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "buyer_order/getalldatabuyer_order?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged2 = resp.data.data;
            this.list_paged2.forEach((item) => {
              var dateFrom = item.tanggal_produksi_start;
              var dateTo = item.tanggal_produksi_end;
              var today = new Date();
              var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
              var dateCheck = date;

              var d1 = dateFrom.split("-");
              var d2 = dateTo.split("-");
              var c = dateCheck.split("-");
              if (c > d1 && c < d2) {
                this.status = "RUNNING";
                this.labeldata = "label label-info";
              } else if (c < d1) {
                this.status = "QUEUES";
                this.labeldata = "label label-warning";
              } else if (c > d2) {
                this.status = "EXPIRED";
                this.labeldata = "label label-danger";
              }
            });
            this.count = resp.data.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIgetdet =
        this.$apiurl +
        "request_material/getalldatarequest_material?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&cari=" +
        this.namesearch;
      axios
        .get(urlAPIgetdet, {
          params,
          headers: { Authorization: tokenlogin }
        })
        .then((respn) => {
          if (respn.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = respn.data.data;
            this.count = respn.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "request_material/hapusrequest_material/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    },

    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "request_material/getalldatarequest_material?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
